import React, { Component } from 'react';
import {Form, Row, Col, Modal, Button, Tabs, Tab, ButtonGroup} from 'react-bootstrap';
import Spinner from '../common_components/Spinner';
import saveAs from 'file-saver';
import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
import DataGrid from '../common_components/DataGrid.js';
import OrderForm from '../orders/OrderForm.js';
import {mapArrayToObject, convertTimestampToDate, convertDateToTimestamp, getAPI, postAPI, getLoggedInUser, openBirtReport, constants, resetObject, validateForm, toCurrency, saveSpecOutput, showFormValidationAlert, roundOff} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import '../FormPage.css';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');

class FormulaFormDeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteFormulaConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}
class FormulaFormCloseConfirmation extends Component {
	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.closeConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={this.props.onConfirm}><Translate value='components.buttons.yes' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.no' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}

let validationFields = {
	name: "required",
	formulaTypeLookupId: "required",
	formulaStatusLookupId: "required",
	substrates: "required",
	aniloxes: "required",
	formulaPhMin: "required,numeric",
	formulaPhMax: "required,numeric",
	formulaViscosityMin: "required,numeric",
	formulaViscosityMax: "required,numeric",
	viscosityCupLookupId: "required",
	formulaHazardHealth: "required,numeric",
	formulaHazardFlammability: "required,numeric",
	formulaHazardReactivity: "required,numeric",
	formulaHazardPersonalProtection: "required",
	customers: "notEmpty",
	// components: "",
	newFormulaChangeReason: "",
	formulaComponents: "notEmpty",
};

class NewFormulaForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
			
		validationFields.newFormulaChangeReason = "";
		const errorsTemplate = resetObject(validationFields);
		this.state = {
			activeTabKey: 1,
			title: "New Formula",
			errors: errorsTemplate,
			validationErrors: false,
			unsavedChanges: false,
			isLoading: false,
			formData: {
				aniloxes:"", // comma separated string of the typed in aniloxes
				aniloxIds:null,
				aniloxValues:null,
				custFormulaNumber:"",	// prefix-formulaID-suffix
				formulaChangeReason:null,
				formulaHazardFlammability:"1",
				formulaHazardHealth:"1",
				formulaHazardPersonalProtection:"C",
				formulaHazardReactivity:"0",
				formulaNotes:"",
				formulaPhMax:null,
				formulaPhMin:null,
				formulaPrefix:"",
				formulaStatusLookupId:85, // Active
				formulaSuffix:"",
				formulaTypeLookupId:0,
				formulaViscosityMax:null,
				formulaViscosityMin:null,
				id:0,
				labRequestNumber:"",
				model:"com.phototype.myinkiq.model.vos.dtos::FormulasDto",
				name:"",
				salesOrderFormulaReferenceId:null,
				substrates:null, // Comma separated list of substrate ids 
				targetCreatedBy:null,
				targetCreatedDate:null,
				targetDeviceTypeLookupId:null,
				targetReadId:0,
				totalRawMaterialCostPerUm:0.0,
				viscosityCupLookupId:159, // Din 4
				bInternalFormula: false,
				bColorConnexion: false,
			},
			customerFormData: {
				approvedBy:null,
				approvedDate:null,
				colorName:"",
				customerId:0,
				formulaId:0,
				id:0,
				isVisible:false,
				isApproved: false,
				lastOrderedDate:null,
				model:"com.phototype.myinkiq.model.vos.dtos::FormulaCustomersListDto",
				name:"",
				notes:"",
				statusLookupId:0,
				userId:0,
				visibleToCustomerFlag: false,
			},
			formulaChangeFormData: {
				formulaId: null,
				changeReason: null,
				changeDate: null,
				changeBy: null,
				model: "com.phototype.myinkiq.model.vos.dtos::FormulaChangeReasonDto",
            },
			showNewFormulaComponentForm: false,
			showDeleteConfirmation: false,
			showCloseConfirmation: false,
			editFormulaComponentRow: null,
			deletedFormulaComponents: [],
			selectedComponentRowIndex: null,
			
			showDefaultWeightForm: false,
			editDefaultWeightRow: null,
			deletedDefaultWeights: [],
			selectedDefaultWeightRowIndex: null,

			modifiedData: {},
			formulaOrders: [],		// Formula Orders
			initialFormulas:[],
			formulaComponents: [], // Formula Components
			formulaDefaultWeights: [],
			formulaCustomers: [], // Formula Customers
			formulaHazardPictograms: [],
			formulaSubstrates: [],
			formulaAniloxes: [], // Array representation of aniloxes
			substrates: [], // Array containing the value for the Multi-Select field, e.g. [{ value: '1', label: 'Strawberry' }, { value: '2', label: 'Vanilla' }]
			customers: [], // Array containing the value for the Multi-Select field, e.g. [{ value: '1', label: 'Strawberry' }, { value: '2', label: 'Vanilla' }]
			hazardPictograms: [{
				value: constants.exclamation_point_id, 
				label: 'Exclamation Point'
			}],
			
			substratesList: [], // List of substrates to select from
			customersList: [],	// list of customers to select from
			initInternalFormulaComponents: [],
			internalFormulaComponents: [],
			componentsList: [],
			
			/////////////////////// FORMULA CUSTOMERS DATA ////////////////////////
			formulaCustomerId: null,
			containers: [],
			formulaCustomerPricing: [],
			customerFormulas: [],
			
			rawMaterialCost: 0.0,
			shrinkageMarkupPercent: 0.00,
			shrinkageMarkup: 0.0,
			basePrice: 0.0,
			containterCost: 0.0,
			overheadMarkupPercent: 0.00,
			overheadMarkup: 0.0,
			companyMiscCharges: 0.0,
			shipping: 0.0,
			priceAfterConversion: null,
			
			bShowFormulaCustomerPricingForm: false,
			editFormulaCustomerPricingRow: null,
			deletedFormulaCustomerPricing: [],
			selectedFormulaCustomerPricingRowIndex: null,
			bComponentsAdjusted: false,
			editOrderId: null,
			saveDisabled: false,
			newFormulaChangeReason: "",
			editFormulaId: this.props.editFormulaId,
			viscosityCups: {},
		};
		this.handleTabSelect = this.handleTabSelect.bind(this);
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.addFormulaComponent = this.addFormulaComponent.bind(this);
		this.removeFormulaComponent = this.removeFormulaComponent.bind(this);
		this.editFormulaComponent = this.editFormulaComponent.bind(this);
		this.componentRowSelected = this.componentRowSelected.bind(this);
		this.saveFormulaComponent = this.saveFormulaComponent.bind(this);
		
		this.addDefaultWeight = this.addDefaultWeight.bind(this);
		this.removeDefaultWeight = this.removeDefaultWeight.bind(this);
		this.editDefaultWeight = this.editDefaultWeight.bind(this);
		this.defaultWeightRowSelected = this.defaultWeightRowSelected.bind(this);
		this.saveDefaultWeight = this.saveDefaultWeight.bind(this);
		
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		
		this.formulaCustomerSelected = this.formulaCustomerSelected.bind(this);
		this.handleCustomerInputChanged = this.handleCustomerInputChanged.bind(this);
		
		
		this.onAddFormulaCustomerPricing = this.onAddFormulaCustomerPricing.bind(this);
		this.formulaCustomerPricingRowSelected = this.formulaCustomerPricingRowSelected.bind(this);
		this.onRemoveFormulaCustomerPricing = this.onRemoveFormulaCustomerPricing.bind(this);
		this.onEditFormulaCustomerPricing = this.onEditFormulaCustomerPricing.bind(this);
		this.onSaveFormulaCustomerPricing = this.onSaveFormulaCustomerPricing.bind(this);
		this.calculateTotalRawMaterialCost = this.calculateTotalRawMaterialCost.bind(this);
		this.showEditOrderForm = this.showEditOrderForm.bind(this);
		this.onFormulaTypeChanged = this.onFormulaTypeChanged.bind(this);
		this.initData = this.initData.bind(this);
		
	}
	handleTabSelect(key) {
		this.setState({
			activeTabKey: key,
		});
	}
	
	calculateTotalRawMaterialCost() {
		let rmc = 0.0;
		this.state.formulaComponents.forEach(component => {
			rmc += roundOff(component.cost, 2);
		});
		return rmc;
	}
	
	onFormulaTypeChanged(value) {

		/*let validationFields = {
			name: "required",
			formulaTypeLookupId: "required",
			formulaStatusLookupId: "required",
			substrates: "required",
			aniloxes: "required",
			formulaPhMin: "required,numeric",
			formulaPhMax: "required,numeric",
			formulaViscosityMin: "required,numeric",
			formulaViscosityMax: "required,numeric",
			viscosityCupLookupId: "required",
			formulaHazardHealth: "required,numeric",
			formulaHazardFlammability: "required,numeric",
			formulaHazardReactivity: "required,numeric",
			formulaHazardPersonalProtection: "required",
			customers: "notEmpty",
			newFormulaChangeReason: "",
			formulaComponents: "notEmpty",
		};*/
		let formData = this.state.formData;
		formData.formulaTypeLookupId = value;
		
		if(value == constants.formula_type_finished_ink || value == constants.formula_type_blending_ink) {
			validationFields.substrates = "required";
			validationFields.aniloxes = "required";
		} else {
			validationFields.substrates = "";
			validationFields.aniloxes = "";
		}

		if(value == constants.formula_type_other || value == constants.formula_type_additive || value == constants.formula_type_dispersion) {
			validationFields.formulaPhMin = validationFields.formulaPhMax = "";
			validationFields.formulaViscosityMin = validationFields.formulaViscosityMax = "";
			validationFields.viscosityCupLookupId = "";
			validationFields.formulaComponents = "";
		} else {
			validationFields.formulaPhMin = validationFields.formulaPhMax = "required,numeric";
			validationFields.formulaViscosityMin = validationFields.formulaViscosityMax = "required,numeric";
			validationFields.viscosityCupLookupId = "required";
			validationFields.formulaComponents = "notEmpty";
		}

		this.setState({
		  formData: formData,
		});
	}

	initData() {
		const loggedInUser = getLoggedInUser();
		this.setState({isLoading: true});
		// console.log("init data")
		getAPI("customers/list").then(resCustomers => {
			getAPI("formulas/substrates").then(resSubstrates => {
				let activeCustomers = resCustomers['data'].filter(obj => obj.status == 'Active');
				// console.log("activeCustomers: ", activeCustomers, ", loggedInUser: ", loggedInUser);
				if(loggedInUser.userTypeId == constants.customer_type_id) {
					activeCustomers = activeCustomers.filter(obj => obj.id == loggedInUser.customerId);
				}
					
				const substratesList = resSubstrates['data'];
				
				getAPI("components/list").then(resComponents => {
					
					getAPI("components/vendors/containertypes").then(resContainers => {
						getAPI("common/viscositycups").then(resViscosityCups => {
							var viscosityCups = {};
							for (const element of resViscosityCups['data']) {
								// Do NOT add Din 4
								if(!element['retired'])
									viscosityCups[element['id']] = element['name'];
							};
							
							this.setState({
								customersList: activeCustomers,
								substratesList: substratesList,
								componentsList: resComponents['data'],
								containers: resContainers['data'],
								viscosityCups: viscosityCups,
							});
					
							// EDIT MODE
							if(this.state.editFormulaId) {
								const editFormulaId = this.state.editFormulaId;
								getAPI("formulas/" + editFormulaId).then(resFormula => {
									getAPI("formulas/" + editFormulaId + "/orders/list").then(resFormulaOrders => {
										getAPI("formulas/" + editFormulaId + "/customers/list").then(resFormulaCustomers => {
											getAPI("formulas/" + editFormulaId + "/components").then(resFormulaComponents => {
												getAPI("formulas/" + editFormulaId + "/hazards").then(resFormulaHazards => {
													getAPI("formulas/" + editFormulaId + "/internalFormulaComponents").then(resInternalFormulaComponents => {
														getAPI("formulas/" + editFormulaId + "/weight/exceptions/list").then(	resFormulaDefaultWeights => {
															
														
															var formData = this.state.formData;
															formData = resFormula['data'][0];
															this.onFormulaTypeChanged(formData.formulaTypeLookupId);
															const title = formData.name + '(' + formData.custFormulaNumber + ')';
															formData['model'] = "com.phototype.myinkiq.model.vos.dtos::FormulasDto";
															var customers = resFormulaCustomers['data'].map(obj => ({value: obj.customerId, label: obj.name}));
															var hazardPictograms = resFormulaHazards['data'].map(obj => ({value: obj.hazardLookupId, label: obj.name}));
															const arrSubstrateIDs = formData['substrates'] ? formData['substrates'].split(",") : [];
															var substrates = [];
															// console.log("formData['substrates']: ", formData['substrates'], ", arrSubstrateIDs: ", arrSubstrateIDs, ", substratesList: ", substratesList);
															if(arrSubstrateIDs.length > 0) {
																substrates = arrSubstrateIDs.map(val => {
																	let label = '';
																	const substrateRow = substratesList.filter(obj => (obj.id == val) )[0];
																	if(substrateRow)
																		label = substrateRow['value'];
																	return {value: val, label: label};
																});
															}
															let arrAniloxes = [];
															const formulaAniloxes = formData.aniloxes.split(",").map((strAnilox) => {
																const arrAnilox = strAnilox.split("-");
																const aniloxId = arrAnilox.pop();
																const anilox = arrAnilox[0];
																arrAniloxes.push(anilox);
																return {id: aniloxId, anilox: anilox}
															});
															formData['aniloxes'] = arrAniloxes.join(",");
															
															var internalFormulaComponents = [];
															internalFormulaComponents = resInternalFormulaComponents['data'].map(val => {
																return {value: val.componentId, label: val.componentName};
															});
															// console.log("loaded - resInternalFormulaComponents['data']: ", resInternalFormulaComponents['data'], ", internalFormulaComponents: ", internalFormulaComponents);
															var formulaComponents = resFormulaComponents['data'];
															formulaComponents = formulaComponents.map(fc => {
																roundOff(fc.costPerUm * (fc.percentUsed / 100), 2);
																return fc;
															});
															console.log("formulaComponents: ", formulaComponents);
															
															formData['id'] = editFormulaId;
															this.setState({
																formData: formData,
																initialFormulas: JSON.parse(JSON.stringify(formData)),
																formulaCustomers: resFormulaCustomers['data'],
																formulaComponents: formulaComponents,
																formulaOrders: resFormulaOrders['data'],
																customers: customers,
																hazardPictograms: hazardPictograms,
																formulaHazardPictograms: resFormulaHazards['data'],
																formulaSubstrates: arrSubstrateIDs,
																formulaAniloxes: formulaAniloxes,
																substrates: substrates,
																title: title,
																isLoading: false,
																internalFormulaComponents: internalFormulaComponents,
																initInternalFormulaComponents: resInternalFormulaComponents['data'],
																formulaDefaultWeights: resFormulaDefaultWeights['data'],
															});
														});
													});
												});											
											});
										});
									});						
								});					
							}
							// If color match was completed for a lab request
							else if(this.props.formulaLabRequestId) {							
								const labRequestId = this.props.formulaLabRequestId;
								let formData = this.state.formData;
								const customerId = this.props.customer
								let customer = {value: customerId, label: null}
								getAPI("customers/" + customerId).then(resCustomer => {
									getAPI("salesorders/" + labRequestId).then(resLabRequest => {
										getAPI("salesorders/" + labRequestId + 
											"/formulas/list").then(resLabRequestFormulas => {
											getAPI("salesorders/" + labRequestId +
												"/substrates/").then(resLabRequestSubstrates => {
													customer.label = resCustomer['data'][0].name;
													formData.labRequestNumber = labRequestId;
													let arrLabRequestNumber = [labRequestId];
													let arrSubstrateIDs = [];
													let substrates = [];
													resLabRequestSubstrates['data'].map(row => {
														const substrateId = row.substrateTypeLookupId;
														arrSubstrateIDs.push(substrateId);
														substrates.push({
															value: substrateId,
															label: substratesList.filter(obj => 
																(obj.id == substrateId) )[0]['value']
														})
													});
													const labRequestFormulaIndex = this.props.labRequestFormulaIndex;
													const anilox = resLabRequestFormulas['data'][labRequestFormulaIndex]['anilox'];
													let arrAniloxes = [];
													// const formulaAniloxes = [anilox];										
													formData['aniloxes'] = anilox; // arrAniloxes.join(",");
													formData['name'] = resLabRequestFormulas['data'][labRequestFormulaIndex]['formula'];
													formData['notes'] = resLabRequestFormulas['data'][labRequestFormulaIndex]['formulaNote'];
													formData['salesOrderFormulaReferenceId'] = resLabRequestFormulas['data'][labRequestFormulaIndex]['id'];
													formData['substrates'] = substrates.map(obj => obj.value).join(',');
													const formulaLabRequest = [{orderId: resLabRequest['data'][0].id, salesOrderType: "Lab Request", orderDate: resLabRequest['data'][0].orderDate}];
													
													/*
													// Samee - Should the formulaNumber default to the formulaId?
													const newFormulaId = resLabRequestFormulas['data'][labRequestFormulaIndex]['formulaId']
													console.log("newFormulaId: ", newFormulaId);
													formData['custFormulaNumber'] = newFormulaId;
													*/
													
													const customers = this.state.customers;
													customers[0] = customer;
													this.setState({
														formData: formData,
														customers: customers,
														substrates: substrates,
														formulaOrders: formulaLabRequest,
														isLoading: false,
														// formulaAniloxes: formulaAniloxes,
													});
											});
										});
									});
								});
							}else {
								this.setState({isLoading: false})
							}
						});
					});
				});
			});
		});
	}
	
	componentDidMount() {
		this.initData();
	}
	
	handleInputChanged(event, name) {
		var value = '';
		var formData = this.state.formData;
		// console.log("props.formulalabrequestid : ", this.props.formulaLabRequestId);
		// console.log("props.editFormulaId: " + this.props.editFormulaId);
		if (!this.props.formulaLabRequestId) {
			if(this.props.editFormulaId && name != "customers" 
			&& name != "name" && name != "newFormulaChangeReason" && name != "custFormulaNumber") {
				validationFields.newFormulaChangeReason = "required";
			}
		}
		
		switch(name) {
		
			case 'substrates':
				value = event;
				formData[name] = value.map(obj => obj.value).join(',');
				this.setState({
					substrates: value,
					formData: formData,
				});
				break;
			
			case 'internalFormulaComponents':
				value = event;
				// console.log("name: ", name, ", value: ", value);
				formData[name] = value.map(obj => obj.value).join(',');
				this.setState({
					internalFormulaComponents: value,
					formData: formData,
				});
				break;
			
			case 'customers':
				value = event;
				// formData[name] = value.map(obj => obj.value).join(',');
				this.setState({
					customers: value,
					// formData: formData,
				});
				break;
			
			case 'hazardPictograms':
				value = event;
				// formData[name] = value.map(obj => obj.value).join(',');
				this.setState({
					hazardPictograms: value,
					// formData: formData,
				});
				break;
				
			case 'formulaPrefix':
			case 'formulaSuffix':
				value = event.target.value;
				let formulaPrefix = name == 'formulaPrefix' ? value :
					this.state.formData.formulaPrefix;
				let formulaSuffix = name == 'formulaSuffix' ? value :
					this.state.formData.formulaSuffix;
				const custFormulaNumber = 
				(formulaPrefix ? formulaPrefix + '-' : '')
				+ this.state.formData.id 
				+ (formulaSuffix ? '-' + formulaSuffix : '');
				
				formData.custFormulaNumber = custFormulaNumber;
				formData[name] = value;
				this.setState({
					formData: formData,
				});
				break;
			
			case 'formulaTypeLookupId':
				value = event.target.value;
				this.onFormulaTypeChanged(value);
				break;
			
			case 'newFormulaChangeReason':
				value = event.target.value;
				this.setState({
					newFormulaChangeReason: value,
				});
				break;
				
			default: 
				const target = event.target;
				value = target.type === 'checkbox' ? target.checked : target.value;
				formData[name] = value;
				// console.log("name: ", name, ", value: ", value);
				this.setState({
				  formData: formData,
				});
				break;
		}
		this.setState({
			unsavedChanges: true,
		});
	}
	
	addFormulaComponent(event) {
		event.preventDefault();
		
		this.setState({
			editFormulaComponentRow: null,
			selectedComponentRowIndex: null,
			showNewFormulaComponentForm: true,
		});
	}
	
	addDefaultWeight(event) {
		event.preventDefault();
		
		this.setState({
			editDefaultWeightRow: null,
			selectedDefaultWeightRowIndex: null,
			showDefaultWeightForm: true,
		});
	}
	
	
	removeFormulaComponent(event) {
		event.preventDefault();
		const selectedComponentRowIndex = this.state.selectedComponentRowIndex;
		let formData = this.state.formData;
		
		if(selectedComponentRowIndex != null) {
			var formulaComponents = this.state.formulaComponents;
			var deletedFormulaComponents = this.state.deletedFormulaComponents;
			
			const formulaComponent = this.state.formulaComponents[selectedComponentRowIndex];
			if(formulaComponent.id > 0)
				deletedFormulaComponents.push(formulaComponent);
		
			formulaComponents.splice(selectedComponentRowIndex, 1);
			if(this.props.editFormulaId && !this.props.formulaLabRequestId)
				validationFields.newFormulaChangeReason = "required";
			
			this.setState({
				formulaComponents: formulaComponents,
				deletedFormulaComponents: deletedFormulaComponents,
				bComponentsAdjusted: this.props.editFormulaId && true,
				formData: formData,
			});
		}
	}
	
	removeDefaultWeight(event) {
		event.preventDefault();
		const selectedDefaultWeightRowIndex = this.state.selectedDefaultWeightRowIndex;
		let formData = this.state.formData;

		if(selectedDefaultWeightRowIndex != null) {
			var formulaDefaultWeights = this.state.formulaDefaultWeights;
			var deletedDefaultWeights = this.state.deletedDefaultWeights;
			
			const formulaDefaultWeight = this.state.formulaDefaultWeights[selectedDefaultWeightRowIndex];
			if(formulaDefaultWeight.id > 0)
				deletedDefaultWeights.push(formulaDefaultWeight);
		
			formulaDefaultWeights.splice(selectedDefaultWeightRowIndex, 1);
			
			this.setState({
				formulaDefaultWeights: formulaDefaultWeights,
				deletedDefaultWeights: deletedDefaultWeights,
				formData: formData,
			});
		}
	}
	
	componentRowSelected(rowIndex) {
		this.setState({
			selectedComponentRowIndex: rowIndex,
		});
	}
	
	defaultWeightRowSelected(rowIndex) {
		this.setState({
			selectedDefaultWeightRowIndex: rowIndex,
		});
	}

	editFormulaComponent(editRow) {

		this.setState({
			editFormulaComponentRow: editRow,
			showNewFormulaComponentForm: true,
		});
	}
	
	editDefaultWeight(editRow) {
		this.setState({
			editDefaultWeightRow: editRow,
			showDefaultWeightForm: true,
		});
	}
	
	saveFormulaComponent(formulaComponent) {
		var formulaComponents = this.state.formulaComponents;
		
		formulaComponent.bHasChanged = true;
		if(this.state.selectedComponentRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			formulaComponents[this.state.selectedComponentRowIndex] = formulaComponent;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			formulaComponent['sequenceNumber'] = formulaComponents.length + 1;
			formulaComponents.push(formulaComponent);
		}
		
		
		const totalRawMaterialCostPerUm = this.calculateTotalRawMaterialCost();
		let formData = this.state.formData;
		formData.totalRawMaterialCostPerUm = totalRawMaterialCostPerUm;
		if(this.props.editFormulaId && !this.props.formulaLabRequestId)
			validationFields.newFormulaChangeReason = "required";

		this.setState({
			formulaComponents: formulaComponents,
			showNewFormulaComponentForm: false,
			formData: formData,
			bComponentsAdjusted: this.props.editFormulaId && true,
		});
	}
	
	saveDefaultWeight(defaultWeight) {
		var formulaDefaultWeights = this.state.formulaDefaultWeights;
		
		defaultWeight.bHasChanged = true;
		if(this.state.selectedDefaultWeightRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			formulaDefaultWeights[this.state.selectedDefaultWeightRowIndex] = defaultWeight;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			formulaDefaultWeights.push(defaultWeight);
		}
		
		// if(this.props.editFormulaId && !this.props.formulaLabRequestId)
		//	validationFields.newFormulaChangeReason = "required";

		this.setState({
			formulaDefaultWeights: formulaDefaultWeights,
			showDefaultWeightForm: false,
		});
	}
	
	onSave() {
		this.setState({ saveDisabled: true });
		const loggedInUser = getLoggedInUser();
		
		const activeTabKey = this.state.activeTabKey;
		
		////////////////// SAVING FORMULA INFO ///////////////////
		if(activeTabKey == 1) {
		
		
			// PERFORM VALIDATION CHECKS HERE
			const otherData = {customers: this.state.customers, formulaComponents: this.state.formulaComponents, newFormulaChangeReason: this.state.newFormulaChangeReason};
			let validation = validateForm(this.state.formData, validationFields, otherData);
			
			// if(this.state.formulaComponents.length == 0) {
			//	validation.errors['components'] = "Please select one or more formula components";
			//	validation.result = false;
			// }
			// else {
			if(this.state.formulaComponents.length > 0) {
				let totalComponentPercentage = 0.0;
				this.state.formulaComponents.forEach(component => {
					totalComponentPercentage += parseFloat(component.percentUsed);
				});
				
				// console.log("totalComponentPercentage: ", totalComponentPercentage.toFixed(2));
				if(totalComponentPercentage.toFixed(2) != "100.00") {
					validation.errors['formulaComponents'] = "Component percentage composition must be 100%";
					validation.result = false;
				}
				else {
					validation.errors['formulaComponents'] = "";
				}
				
				// console.log("this.state.bComponentsAdjusted: ", this.state.bComponentsAdjusted);
				/*
				if(validationFields.newFormulaChangeReason == "required")
				{
					if(!this.state.formData.newFormulaChangeReason) {
						validation.errors['newFormulaChangeReason'] = "Please state a reason.";
						validation.result = false;
					}
				}*/
			}
			
			// console.log("validation: ", validation);

		
			// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
			if(!validation.result)
			{
				showFormValidationAlert(validation.errors);
				this.setState({
					errors: validation.errors,
					saveDisabled: false,
					validationErrors: true,
				});
			}
			else
			{
				if (this.state.formData['formulaSuffix'] == null) {
					this.state.formData['formulaSuffix'] = "";
				}
				if (this.state.formData['formulaPrefix'] == null) {
					this.state.formData['formulaPrefix'] = "";
				}
				//praphulla saving the actual reason for the table 
				let modifiedData = this.state.modifiedData;
				const initialFormulas = this.state.initialFormulas;
				modifiedData = [];
				let actualFormulaChange = '';
				var newData = this.state.formData;
				// Tracking changes in Formula tab
				const fieldsToCompare = {'aniloxes': 'Anilox', 'custFormulaNumber': 'Formula#', 'formulaHazardFlammability': 'Flammability', 'formulaHazardHealth': 'Health', 'formulaHazardPersonalProtection': 'Personal Protection', 'formulaHazardReactivity': 'Reactivity', 'formulaNotes': 'Notes', 'formulaPhMax': 'Max PH', 'formulaPhMin': 'Min PH', 'formulaPrefix': 'Prefix', 'formulaStatusLookupId': 'Status LookupId', 'formulaSuffix': 'Suffix', 'formulaTypeLookupId': 'TypeLookupId', 'formulaViscosityMax': 'Max Viscosity', 'formulaViscosityMin': 'Min Viscosity', 'id': 'Id'};
				//newData.forEach(function(row, i){
					//var initialRow = initialFormulas.filter(obj => obj.id == row.id)[0];
					let FormulaChanges = [];
					
					if(initialFormulas != undefined) {
						Object.keys(fieldsToCompare).forEach(function(field) {
							if(initialFormulas[field] != newData[field]){
							//FormulaChanges[fieldsToCompare[field]] = {'old': "" + initialFormulas[field], 'new': "" + newData[field]};
							   FormulaChanges = [fieldsToCompare[field]+ " is changed from " + initialFormulas[field] + " to " + newData[field]];
							   modifiedData.push(FormulaChanges);
							}
						});
						actualFormulaChange = modifiedData.join(" , ");
						//console.log(actualFormulaChange);
					} else {
						Object.keys(fieldsToCompare).forEach(function(field) {
						//FormulaChanges[fieldsToCompare[field]] = {'old': "", 'new': "" + newData[field]};
						FormulaChanges = [" A new value of " +  newData[field] + "is given to " + fieldsToCompare[field]];
						modifiedData.push(FormulaChanges);
						});	
						actualFormulaChange = modifiedData.join(" , ");
					}
					// console.log("actualFormulaChange: ", actualFormulaChange);
				//praphulla end 

				// Setting formulaChangeReason
				let formulaChangedReasonsPromise = [];
				if(this.props.editFormulaId  && !this.props.formulaLabRequestId) {
					const changeReason = this.state.newFormulaChangeReason;
					const changeDate = (new Date()).getTime();
					const changeBy = loggedInUser.firstName + " " + loggedInUser.lastName;
					
					this.state.formData.formulaChangeReason = "Changed On: " 
					+ convertTimestampToDate((new Date()).getTime(), true) 
					+ " by " + loggedInUser.firstName + " " + loggedInUser.lastName + " - " 
					+ this.state.newFormulaChangeReason + "\n" + this.state.formData.formulaChangeReason;
					
					formulaChangedReasonsPromise.push(postAPI("/formulas/" + this.props.editFormulaId + "/changeReason", {
						formulaId: this.props.editFormulaId, 
						changeReason: changeReason, 
						actualChange: actualFormulaChange, 
						changeDate: changeDate, 
						changeBy: changeBy 
					}));
					/*
					this.state.formulaChangeFormData.changeReason = this.state.newFormulaChangeReason;
					this.state.formulaChangeFormData.modifiedDate = convertTimestampToDate((new Date()).getTime(), true);
					this.state.formulaChangeFormData.modifiedBy = loggedInUser.firstName + " " + loggedInUser.lastName;
					this.state.formulaChangeFormData.formulaName = this.state.formData.name;
					*/
				}

				// console.log("this.state.formulaComponents: ", this.state.formulaComponents);
				// IF FORM IS VALIDATED PERFORM SAVE OPERATION HERE
				postAPI("/formulas", this.state.formData).then(resFormula => {
					if (resFormula.error != undefined) {
						alert("Error saving formula: " + resFormula.error['error'])
						this.setState({
							saveDisabled: false,
						});
					}
					else {
						var formulaId = resFormula['data'][0]['id'];
						this.reactGA.gaEvent("Formula", "Save", "Formula saved successfully.");
					
						var fomulaDeletedComponentPromises = []
						for(const i in this.state.deletedFormulaComponents) {
							const formulaComponent = this.state.deletedFormulaComponents[i];
							fomulaDeletedComponentPromises.push(postAPI("/formulas/" + formulaId + "/components/" + formulaComponent.id, formulaComponent).then(resFormulaComponent => {

							}));
				
						}
						
						var formulaComponentPromises = [];
						
						for(const i in this.state.formulaComponents) {
							var formulaComponent = this.state.formulaComponents[i];
							formulaComponent['formulaId'] = formulaId;
							if(formulaComponent.bHasChanged != undefined && formulaComponent.bHasChanged)
							{
								delete(formulaComponent.bHasChanged);
								formulaComponentPromises.push(postAPI("/formulas/" + formulaId + "/components", formulaComponent).then(resComponent => {
									// console.log("resComponent: ", resComponent);
									if(resComponent['error']) {
										alert("Error " + resComponent['error']['status'] + " while saving formula component " + formulaComponent["id"] + " - " + resComponent['error']['error'])
									}
								}));
							}
							
						}
						
						
						// Default Weights
						var deletedDefaultWeightPromises = []
						for(const i in this.state.deletedDefaultWeights) {
							const defaultWeight = this.state.deletedDefaultWeights[i];
							deletedDefaultWeightPromises.push(postAPI("/formulas/" + formulaId + "/weight/exceptions/" + defaultWeight.id, defaultWeight).then(resFormulaComponent => {

							}));
				
						}
						
						var defaultWeightPromises = [];
						
						for(const i in this.state.formulaDefaultWeights) {
							var defaultWeight = this.state.formulaDefaultWeights[i];
							defaultWeight['formulaId'] = formulaId;
							if(defaultWeight.bHasChanged != undefined && defaultWeight.bHasChanged)
							{
								delete(defaultWeight.bHasChanged);
								defaultWeightPromises.push(postAPI("/formulas/" + formulaId + "/weight/exceptions/", defaultWeight).then(resComponent => {
									// console.log("resComponent: ", resComponent);
									if(resComponent['error']) {
										alert("Error " + resComponent['error']['status'] + " while saving formula default weight " + defaultWeight["id"] + " - " + resComponent['error']['error']);
									}
								}));
							}
							
						}
						
						
			
						// Hazards
				
						const formulaHazardPictograms = this.state.formulaHazardPictograms;
						const hazardPictograms = this.state.hazardPictograms;

						var hazardPictogramPromises = []
						for(const i in hazardPictograms) {
							const hazardPictogram = hazardPictograms[i];
							// Determine whether to insert / edit substrate based on existing id
							const hazardPictogramRow = formulaHazardPictograms.filter(obj => obj.hazardLookupId == hazardPictogram.value)[0];
							const id = hazardPictogramRow ? hazardPictogramRow['id'] : 0;
							let formulaHazardPictogram = {"model":"com.phototype.myinkiq.model.vos.dtos::FormulaHazardsDto","id": id, "formulaId":formulaId,"hazardLookupId":hazardPictogram.value,"name":hazardPictogram.label};
					
							hazardPictogramPromises.push(postAPI("formulas/" + formulaId + "/hazards/", formulaHazardPictogram).then(resFormulaHazardPictogram => {
							}));
						}
				
						var formulaHazardPictogramPromises = []
						for(const i in formulaHazardPictograms) {
							// Determining which substrate to delete based on non-existent substrate
							const formulaHazardPictogram = formulaHazardPictograms[i];
							const hazardPictogramRow = hazardPictograms.filter(obj => obj.value == formulaHazardPictogram.hazardLookupId)[0];
							if(!hazardPictogramRow)
							{
								formulaHazardPictogramPromises.push(postAPI("formulas/" + formulaId + "/hazards/" + formulaHazardPictogram.id, formulaHazardPictogram).then(resFormulaHazardPictogram => {
								}));
							}
						}
				
				
				
				
				
			
						// Aniloxes
						const aniloxBody = {"model":"com.phototype.myinkiq.model.vos.dtos::FormulaAniloxesDto",
							"id":0,
							"formulaId":formulaId};
				
						const aniloxes = this.state.formData.aniloxes.split(",");
						const formulaAniloxes = this.state.formulaAniloxes;
				

						var addAniloxPromises = []
						aniloxes.forEach(anilox => {
							const aniloxRow = formulaAniloxes.filter(obj => obj.anilox == anilox)[0];
							if(!aniloxRow)
							{
								aniloxBody["anilox"] = anilox;
								aniloxBody["name"] = anilox;
								addAniloxPromises.push(postAPI("formulas/" + formulaId + "/aniloxes", aniloxBody).then(resAniloxes => {
								}));
							}
						});
						// Deleting aniloxes
						var deleteAniloxPromises = []
						formulaAniloxes.forEach((objAnilox, i) => {
					
							const aniloxRow = aniloxes.filter(val => objAnilox.anilox == val)[0];
							if(!aniloxRow) {
								aniloxBody["anilox"] = objAnilox.anilox;
								aniloxBody["id"] = objAnilox.id;
						
								deleteAniloxPromises.push(postAPI("formulas/" + formulaId + "/aniloxes/" + objAnilox.id, aniloxBody).then(resAnilox => {
						
								}));
							}
						});
			
				
						// Substrates
				
						const formulaSubstrates = this.state.formulaSubstrates;
						const substrates = this.state.substrates;
						const internalFormulaComponents = this.state.internalFormulaComponents;
						

						var addSubstratePromises = []
						for(const i in substrates) {
							const substrate = substrates[i];						
							// Determine whether to insert / edit substrate based on existing id
							const substrateRow = formulaSubstrates.filter(obj => obj == substrate.value)[0];
							if(!substrateRow)
							{
								let formulaSubstrate = {"model":"com.phototype.myinkiq.model.vos.dtos::FormulaSubstratesDto","sequenceNumber": i + 1,"id":0,"formulaId":formulaId,"substrateLookupId":substrate.value,"name":substrate.label};

								addSubstratePromises.push(postAPI("formulas/" + formulaId + "/substrates", formulaSubstrate).then(resFormulaSubstrate => {

								}));
							}
					
					
						}
				
						var removeSubstratePromises = []
						for(const i in formulaSubstrates) {
							// Determining which substrate to delete based on non-existent substrate
							const formulaSubstrate = formulaSubstrates[i];
							const substrateRow = substrates.filter(obj => obj.value == formulaSubstrate)[0];
							if(!substrateRow)
							{
								const objFormulaSubstrate = {"model":"com.phototype.myinkiq.model.vos.dtos::FormulaSubstratesDto", "formulaId":formulaId,"substrateLookupId":substrateRow}
								removeSubstratePromises.push(postAPI("formulas/" + formulaId + "/substrates/" + formulaSubstrate, objFormulaSubstrate).then(resFormulaSubstrate => {
								}));
							}
						}
						
						
						
						// Internal Formula Components
						var addInternalFormulaComponentPromises = [];
						const initInternalFormulaComponents = this.state.initInternalFormulaComponents;
						// console.log("saving - initInternalFormulaComponents: ", initInternalFormulaComponents, ", internalFormulaComponents: ", internalFormulaComponents);
						for(const i in internalFormulaComponents) {
							const comp = internalFormulaComponents[i];
							const compRow = initInternalFormulaComponents.filter(obj => obj.componentId == comp.value)[0];
							if(!compRow) {
								let formulaComp = {
									"model":"com.phototype.myinkiq.model.vos.dtos::InternalFormulaComponentsDto", 
									"id":0, 
									"formulaId": formulaId, 
									"componentId": comp.value
								};

								addInternalFormulaComponentPromises.push(postAPI("formulas/" + formulaId + "/internalFormulaComponents/", formulaComp).then(resFormulaComp => {

								}));
							}
						}
						
						
						var removeInternalFormulaComponentsPromises = [];
						for(const i in initInternalFormulaComponents) {
							const comp = initInternalFormulaComponents[i];
							const compRow = internalFormulaComponents.filter(obj => obj.value == comp.componentId)[0];
							if(!compRow) {
								let formulaComp = {"model":"com.phototype.myinkiq.model.vos.dtos::InternalFormulaComponentsDto", "id": comp.id, "formulaId":comp.formulaId, "componentId": comp.componentId};

								removeInternalFormulaComponentsPromises.push(postAPI("formulas/" + formulaId + "/internalFormulaComponents/" + comp.componentId, formulaComp).then(resFormulaComp => {

								}));
							}
						}
						
			
						// Customers
						var addFormulaCustomerPromises = [];
						const formulaCustomers = this.state.formulaCustomers;
						const customers = this.state.customers;
						for(const i in customers) {
							const customer = customers[i];
							// Determine whether to insert / edit substrate based on existing id
							const customerRow = formulaCustomers.filter(obj => obj.customerId == customer.value)[0];
							if(!customerRow) {
								let formulaCustomer = {"model":"com.phototype.myinkiq.model.vos.dtos::FormulaCustomersDto","customerId":customer.value,"id":0,"formulaId":formulaId,"statusLookupId":this.state.formData.formulaStatusLookupId,"name":null};
					
								addFormulaCustomerPromises.push(postAPI("formulas/" + formulaId + "/customers", formulaCustomer).then(resFormulaCustomer => {
								}));
							}
					
						}
						
						var removeFormulaCustomerPromises = [];
						for(const i in formulaCustomers) {
							// Determining which substrate to delete based on non-existent substrate
							const formulaCustomer = formulaCustomers[i];
							const customerRow = customers.filter(obj => obj.value == formulaCustomer.customerId)[0];
							if(!customerRow)
							{
								removeFormulaCustomerPromises.push(postAPI("formulas/" + formulaId + "/customers/" + formulaCustomer.id, formulaCustomer).then(resFormulaCustomer => {
								}));
							}
						}

						Promise.all(fomulaDeletedComponentPromises)
						.then(() => {
							Promise.all(formulaComponentPromises)
							.then(() => {
								Promise.all(hazardPictogramPromises)
								.then(() => {
									Promise.all(formulaHazardPictogramPromises)
									.then(() => {
										Promise.all(addAniloxPromises)
										.then(() => {
											Promise.all(deleteAniloxPromises)
											.then(() => {
												Promise.all(addSubstratePromises)
												.then(() => {
													Promise.all(removeSubstratePromises)
													.then(() => {
														Promise.all(addFormulaCustomerPromises)
														.then(() => {
															Promise.all(removeFormulaCustomerPromises)
															.then(() => {
																Promise.all(formulaChangedReasonsPromise)
																.then(() => {
																	Promise.all(addInternalFormulaComponentPromises)
																	.then(() => {
																		Promise.all(removeInternalFormulaComponentsPromises)
																		.then(() => {
																			alert("Formula info has been saved successfully.");
																			const errorsTemplate = resetObject(validationFields);
																			let formData = this.state.formData;
																			formData.id = formulaId;
																			this.setState({ 
																				saveDisabled: false, 
																				editFormulaId: formulaId, 
																				errors:errorsTemplate, 
																				validationErrors: false,
																				unsavedChanges: false,
																				//formData: formData
																			});
																			this.props.onSave(formulaId);
																			// console.log("this.props.formulaLabRequestId not null");
																			if (this.props.formulaLabRequestId)	{
																				// console.log("this.initData called");
																				this.initData();
																			}	
																		});
																	});
																});
															});
														});
													});
												});
											});
										});
									});
								});
							});
						});
					}
				});
			}
		}	
		else if(activeTabKey == 2) {
			//////////////////////// SAVING FORMULA CUSTOMER INFO ///////////////////////
			const formulaId = this.state.formData.id;
			const customerId = this.state.formulaCustomerId;
			var customerInfo = this.state.customerFormData;
			
			const loggedInUser = getLoggedInUser();
			if(this.state.customerFormData.isApproved) {
				customerInfo['approvedBy'] = loggedInUser['firstName'] + ' ' + loggedInUser['lastName'];
				customerInfo['approvedDate'] = (new Date()).getTime();
			}
			else {
				customerInfo['approvedBy'] = null;
				customerInfo['approvedDate'] = null;
			}
			customerInfo['userId'] = loggedInUser.id;
			
			postAPI("/formulas/" + formulaId + "/customers/" + customerId + "/info", customerInfo)
			.then(res => {	
				var formulaCustomerPricing = [];
				for(const i in this.state.formulaCustomerPricing) {
					const formulaCustomerPrice = this.state.formulaCustomerPricing[i];
					formulaCustomerPrice['formulaId'] = formulaId;
					formulaCustomerPrice['customerId'] = customerId;
					formulaCustomerPrice['model'] = "com.phototype.myinkiq.model.vos.dtos::FormulaCustomerContainerPricingDto";
					
					formulaCustomerPricing.push(postAPI("/formulas/" + formulaId + "/customers/" + customerId + "/pricing", formulaCustomerPrice).then(resFormulaCustomerPricing => {
					}));
				}
				var deletedFormulaCustomerPricing = [];
				for(const i in this.state.deletedFormulaCustomerPricing) {
					const formulaCustomerPrice = this.state.deletedFormulaCustomerPricing[i];
					deletedFormulaCustomerPricing.push(postAPI("/formulas/" + formulaId + "/customers/" + customerId + "/pricing/" + formulaCustomerPrice.id, formulaCustomerPrice).then(resFormulaCustomerPricing => {
					}));
				}
				Promise.all(formulaCustomerPricing)
				.then((formulaCustomerPricingRes) => {
					Promise.all(deletedFormulaCustomerPricing)
					.then((deletedFormulaCustomerPricingRes) => {
						alert("Formula Customer info has been saved successfully");
						this.props.onSave();
					});
				});
			});
		}
	}
	
	onDelete() {
		postAPI("formulas/" + this.state.formData.id, this.state.formData).then(resFormula => {
			if (resFormula.error != undefined) {
				alert("Error saving formula: " + resFormula.error['error']);
				return false;
			}
			// alert(<Translate value="components.labels.formulaDeleted" />);
			alert("Formula info has been deleted successfully");
			this.reactGA.gaEvent("Formula", "Delete", "Formula deleted successfully.");
			this.props.onSave();
		});
	}
	
	
	
	formulaCustomerSelected(rowIndex) {
		const formulaCustomer = this.state.formulaCustomers[rowIndex];
		
		const customerId = formulaCustomer['customerId'];
		const formulaId = this.state.formData.id;		
		getAPI("customers/" + customerId + "/pricing").then(resCustomerPricing => { 
			const rawMaterialCost = this.state.formData.totalRawMaterialCostPerUm; // formulaRow['rawMaterialCost'];
			const shrinkageMarkupPercent = resCustomerPricing['data'][0]['companyShrinkageMarkup'];
			const companyContainerCost = resCustomerPricing['data'][0]['companyContainerCost'];
			const overheadMarkupPercent = resCustomerPricing['data'][0]['overHeadMarkupPercent'];
			
			const companyMiscCharges = resCustomerPricing['data'][0]['companyMiscCharge'];
			const shipping = resCustomerPricing['data'][0]['shippingCost'];
			const umCode = resCustomerPricing['data'][0]['umCode'];
			
			const shrinkageMarkup = shrinkageMarkupPercent * rawMaterialCost / 100;
			const basePrice = rawMaterialCost + shrinkageMarkup;
			const basePricePlusContainer = basePrice + companyContainerCost;
			const overheadMarkup = overheadMarkupPercent * basePricePlusContainer / 100;
			
			const basePricePlusContainerPlusMarkup = basePricePlusContainer 
				+ overheadMarkup;
			const basePricePlusContainerPlusAllMarkups = basePricePlusContainerPlusMarkup 
				+ companyMiscCharges;
			const basePricePlusContainerPlusMarkupPlusShipping = 
				basePricePlusContainerPlusAllMarkups + shipping;
		
			// Samee - have to set this in the config file
			var conversionFactor = 2.2; // Config.weightConversionUnit; // kg to lbs	
			let priceAfterConversion = (umCode == 'Kg' && Config.locale === "EN_US") ? 
				basePricePlusContainerPlusMarkupPlusShipping * conversionFactor : (
				(umCode == 'Lb' && Config.locale === "ES_MX") ? basePricePlusContainerPlusMarkupPlusShipping / conversionFactor: null
				);
			
			// console.log("umCode: ", umCode, ", Config.locale: ", Config.locale, ", basePricePlusContainerPlusMarkupPlusShipping: ", basePricePlusContainerPlusMarkupPlusShipping, ", conversionFactor:" , conversionFactor, ", priceAfterConversion: ", priceAfterConversion);
			
			let unitWt = umCode;

			this.setState({
				rawMaterialCost: rawMaterialCost,
				shrinkageMarkupPercent: shrinkageMarkupPercent,
				shrinkageMarkup: shrinkageMarkup,
				basePrice: basePrice,
				basePricePlusContainer: basePricePlusContainer,
				overheadMarkupPercent: overheadMarkupPercent,
				basePricePlusContainerPlusMarkup: basePricePlusContainerPlusMarkup,
				basePricePlusContainerPlusAllMarkups: basePricePlusContainerPlusAllMarkups,
				basePricePlusContainerPlusMarkupPlusShipping: basePricePlusContainerPlusMarkupPlusShipping,
				unitWt: unitWt,
				priceAfterConversion: priceAfterConversion,
			});
		});
		getAPI("formulas/" + formulaId + "/customers/" + customerId + "/pricing").then(resFormulaCustomerPricing => {
			getAPI("customers/" + customerId + "/formulas").then(resCustomerFormulas => {
				
				let filteredFormulas = resCustomerFormulas['data'].filter(form => form.status == "Active");
				filteredFormulas = filteredFormulas.filter(form => form.formulaType != "Blending Ink")
				// console.log(filteredFormulas)
				var formulaCustomerPricing = [];
				for(const i in resFormulaCustomerPricing['data']) {
					var formulaCustomerPrice = resFormulaCustomerPricing['data'][i];
					const containerId = formulaCustomerPrice.containerId;
					formulaCustomerPrice['containerName'] = this.state.containers.filter(obj => obj.id == containerId)[0]['name'];
					formulaCustomerPrice['model'] = "com.phototype.myinkiq.model.vos.dtos::FormulaCustomerContainerPricingDto";
					formulaCustomerPricing.push(formulaCustomerPrice);
				}
				
				// var formulaRow = filteredFormulas.filter(obj => obj.formulaId == formulaId)[0];
				// const customerColorName = formulaRow['customerFormulaName'];
				var customerFormData = formulaCustomer;// this.state.customerFormData;
				customerFormData['model'] = "com.phototype.myinkiq.model.vos.dtos::FormulaCustomersListDto";
				customerFormData['isVisible'] = false;
				customerFormData['isApproved'] = customerFormData['approvedBy'] != null;
				
				this.setState({
					formulaCustomerPricing: formulaCustomerPricing,
					customerFormulas: filteredFormulas,
					customerFormData: customerFormData,
					formulaCustomerId: customerId,
				});
			});
		});
	}
	
	handleCustomerInputChanged(event, name) {
		var customerFormData = this.state.customerFormData;
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;

		customerFormData[name] = value;

		this.setState({
		  customerFormData: customerFormData,
		});
	}
	
	onAddFormulaCustomerPricing(event) {
		event.preventDefault();
		
		this.setState({
			editFormulaCustomerPricingRow: null,
			selectedFormulaCustomerPricingRowIndex: null,
			bShowFormulaCustomerPricingForm: true,
		});
	}
	
	formulaCustomerPricingRowSelected(rowIndex) {

		this.setState({
			selectedFormulaCustomerPricingRowIndex: rowIndex,
		});
	}
	
	onRemoveFormulaCustomerPricing(event) {
		event.preventDefault();
		const selectedFormulaCustomerPricingRowIndex = this.state.selectedFormulaCustomerPricingRowIndex;
		
		if(selectedFormulaCustomerPricingRowIndex != null) {
			var formulaCustomerPricing = this.state.formulaCustomerPricing;
			var deletedFormulaCustomerPricing = this.state.deletedFormulaCustomerPricing;
			
			const formulaCustomerPrice = formulaCustomerPricing[selectedFormulaCustomerPricingRowIndex];
			if(formulaCustomerPrice.id > 0)
				deletedFormulaCustomerPricing.push(formulaCustomerPrice);
		
			formulaCustomerPricing.splice(selectedFormulaCustomerPricingRowIndex, 1);
			this.setState({
				formulaCustomerPricing: formulaCustomerPricing,
				selectedFormulaCustomerPricingRowIndex: null,
				deletedFormulaCustomerPricing: deletedFormulaCustomerPricing,
			});
		}
	}
	
	
	onEditFormulaCustomerPricing(editRow) {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.accessFormulaPricingCode != 'E')
			return false;

		this.setState({
			editFormulaCustomerPricingRow: editRow,
			bShowFormulaCustomerPricingForm: true,
		});
	}
	
	onSaveFormulaCustomerPricing(formulaCustomerPrice) {
		
		formulaCustomerPrice['containerName'] = this.state.containers.filter(obj => obj.id == formulaCustomerPrice.containerId)[0]['name'];
		var formulaCustomerPricing = this.state.formulaCustomerPricing;

		if(this.state.selectedFormulaCustomerPricingRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			formulaCustomerPricing[this.state.selectedFormulaCustomerPricingRowIndex] = formulaCustomerPrice;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			formulaCustomerPricing.push(formulaCustomerPrice);
		}
		

		this.setState({
			formulaCustomerPricing: formulaCustomerPricing,
			bShowFormulaCustomerPricingForm: false,
		});
	}
	
	showEditOrderForm(dataIn) {
		// console.log(dataIn, dataIn[this.state.idField])
		this.setState({
			editOrderId: dataIn['orderId'],
		});
	}
	

	render() {
		const loggedInUser = getLoggedInUser();
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id 
		|| loggedInUser.userTypeId == constants.customer_type_id;
		const bCustomer = loggedInUser.userTypeId == constants.customer_type_id;
		const bVIP = loggedInUser.userTypeId == constants.vip_type_id;
		
		let bShowBlendingTicket = bCustomer || 
			loggedInUser.userReports[constants.blending_ticket_report_id];
			
		let bShowFormulaCost = !bCustomer || (bCustomer && loggedInUser.userReports[constants.formula_cost_report_id]);
		let bShowCustomerTab = !bCustomer || (bCustomer && loggedInUser.accessFormulasCode == 'E');
		
		var statuses = {
			'85': 'Active', // <Translate value='components.labels.active' />,
			'87': 'Inactive', // <Translate value='components.labels.inactive' />,
			'86': 'Experimental', // <Translate value='components.labels.experimental' />,
		};

		var formulaTypes = {
			'88': 'Base', // <Translate value='components.labels.base' />,
			'89': 'Finished Ink', // <Translate value='components.labels.finishedInk' />,
			'90': 'Varnish', // <Translate value='components.labels.varnish' />,
			'91': 'Other', // <Translate value='components.labels.other' />,
			'163': 'Additive', // <Translate value='components.labels.additive' />,
			'164': 'Dispersion', // <Translate value='components.labels.dispersion' />,
			'165': 'OPV', // <Translate value='components.labels.oPV' />,
			'166': 'Blending Ink', // <Translate value='components.labels.blendingInk' />,
		};

		/*var viscosityCups = {
			'159': 'Din 4',
			'160': 'Zahn 3',
			'161': 'Zahn 2 EZ',
			'162': 'Zahn 2 S',
			'176': 'Brookfield',
		}*/
		
		const hazardPictogramsList = [
			{value: constants.corrosion_id, label: 'Corrosion'},
			{value: constants.exclamation_point_id, label: 'Exclamation Point'},
			{value: constants.flame_id, label: 'Flame'},
			{value: constants.respiratory_id, label: 'Respiratory'},
			{value: constants.toxic_id, label: 'Toxic'},
		];

		var ordersColumns = [
			<Translate value="components.labels.orderId" />, 
			<Translate value="components.labels.orderDate" />, 
			<Translate value="components.labels.orderType" />
		];
		var ordersFields = ['orderId', 'orderDate', 'salesOrderType']
		const ordersFormatColumns = {1: {date: true}};

		var componentsColumns = [
			<Translate value="components.labels.sequenceNumber" />,
			<Translate value="components.labels.percentUsed" />,
			<Translate value="components.labels.component" />,
			<Translate value={"components.labels.costPer" + Config.weightSymbol} />,
			<Translate value="components.labels.cost" />,
			<Translate value="components.labels.pigment" />,
			<Translate value="components.labels.resin" />,
		];
		var componentsFields = ['sequenceNumber', 'percentUsed', 'name', 'costPerUm', 'cost', 'pigment', 'resin'];
		
		var defaultWeightsColumns = ["Container", "Weight"];
		var defaultWeightsFields = ["containerName", "weight"];
		
		let formatColumns = {
			1: {showTotal: true, percent: true},
			3: {'currency': true},
			4: {'currency': true, showTotal: true},
			5: {showTotal: true, decimals: 2},
			6: {showTotal: true, decimals: 2},
		};
		
		
		if(bSalesPerson) {
			componentsColumns = [
				<Translate value="components.labels.sequenceNumber" />,
				<Translate value="components.labels.percentUsed" />,
				<Translate value="components.labels.component" />,
				<Translate value="components.labels.pigment" />,
				<Translate value="components.labels.resin" />,
			];
			componentsFields = ['sequenceNumber', 'percentUsed', 'name', 'pigment', 'resin'];
			
			formatColumns = {
				1: {showTotal: true, percent: true},
				3: {showTotal: true},
				4: {showTotal: true},
			};
		}
		
		if(bCustomer) {
			componentsColumns = [
				<Translate value="components.labels.sequenceNumber" />,
				<Translate value="components.labels.percentUsed" />,
				<Translate value="components.labels.component" />,
			];
			componentsFields = ['sequenceNumber', 'percentUsed', 'name'];
			formatColumns = {
				1: {showTotal: true, percent: true},
				
			};
		}
		
		const substratesList = this.state.substratesList.map(obj => ({value: obj.id, label: obj.value}));
		const customersList = this.state.customersList.map(obj => ({value: obj.id, label: obj.name}));
		const internalFormulasComponentsList = this.state.componentsList.map(obj => ({value: obj.id, label: obj.name + ' (' + obj.component + ')'}));
		const bInternalFormula = this.state.formData.bInternalFormula;
		// console.log("render() - bInternalFormula: ", bInternalFormula);
		
		const editFormulaComponentRow = this.state.editFormulaComponentRow;
		const formulaComponentForm = this.state.showNewFormulaComponentForm ? 
		(<NewFormulaComponentForm
			onClose={() => this.setState({showNewFormulaComponentForm: false})}
			onSave={this.saveFormulaComponent}
			visible={this.state.showNewFormulaComponentForm}
			editFormulaComponentRow={editFormulaComponentRow}
			componentsList={this.state.componentsList}
			activeComponentsList={this.state.componentsList.filter(row => row.status == 'Active')}
			/>) 
			: '';
		
		var orderFormContent = this.state.editOrderId != null ? <OrderForm
			visible={this.state.editOrderId != null}
			onClose={() => this.setState({editOrderId: null})}
			onSave={this.handleSaveOrder}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		const editDefaultWeightRow = this.state.editDefaultWeightRow;
		var formulaDefaultWeightForm = this.state.showDefaultWeightForm ? 
		(<FormulaDefaultWeightForm
			onClose={() => this.setState({showDefaultWeightForm: false})}
			onSave={this.saveDefaultWeight}
			visible={this.state.showDefaultWeightForm}
			editDefaultWeightRow={editDefaultWeightRow}
			containers={this.state.containers}
			/>) 
			: '';

		const formulasTab = <Form className='form-page' horizontal>
			<Row>
				<Col md={4}>
					<InputText label={<Translate value="components.labels.prefix" />} 
						id={"formulaPrefix"} name={"formulaPrefix"} 
						value={this.state.formData.formulaPrefix} 
						action={this.handleInputChanged} >
					</InputText>
					<InputText label={<Translate value="components.labels.suffix" />} 
						id={"formulaSuffix"} name={"formulaSuffix"} 
						value={this.state.formData.formulaSuffix} 
						action={this.handleInputChanged}>
					</InputText>
					<InputText label={<Translate value="components.labels.formulaNumber" />} 
						id={"custFormulaNumber"} name={"custFormulaNumber"} 
						value={this.state.formData.custFormulaNumber} 
						action={this.handleInputChanged}>
					</InputText>
					<InputText label={<Translate value="components.labels.name" />} 
						required={validationFields.name == 'required'} 
						id={"name"} name={"name"} value={this.state.formData.name} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.name}>
					</InputText>
					<DataDropdown label={<Translate value="components.labels.type" />} 
						required={validationFields.formulaTypeLookupId == 'required'}  
						id={"formulaTypeLookupId"} name={"formulaTypeLookupId"} 
						data={formulaTypes} value={this.state.formData.formulaTypeLookupId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaTypeLookupId}>
					</DataDropdown>
					<DataDropdown label={<Translate value="components.labels.status" />} 
						required={validationFields.formulaStatusLookupId == 'required'} 
						id={"formulaStatusLookupId"} name={"formulaStatusLookupId"} 
						data={statuses} value={this.state.formData.formulaStatusLookupId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaStatusLookupId}>
					</DataDropdown>
					<InputText label={<Translate value="components.labels.substrate" />} 
						required={validationFields.substrates == 'required'}  
						id={"substrates"} name={"substrates"} type={"MultiSelect"} 
						data={substratesList} value={this.state.substrates} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.substrates}>
					</InputText>
					<InputText label={<Translate value="components.labels.anilox" />} 
						required={validationFields.aniloxes == 'required'} type={"TextArea"} 
						id={"aniloxes"} name={"aniloxes"} value={this.state.formData.aniloxes} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.aniloxes}>
					</InputText>
					<InputText label={<Translate value="components.labels.labRequestId" />} 
						id={"labRequestNumber"} name={"labRequestNumber"} 
						value={this.state.formData.labRequestNumber} action={this.handleInputChanged}>
					</InputText>
				</Col>
				<Col md={4}>
					<InputText label={<Translate value="components.labels.minPH" />} 
						required={validationFields.formulaPhMin == 'required,numeric'} 
						id={"formulaPhMin"} name={"formulaPhMin"} 
						value={this.state.formData.formulaPhMin} action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaPhMin}>
					</InputText>
					<InputText label={<Translate value="components.labels.maxPH" />} 
						required={validationFields.formulaPhMax == 'required,numeric'} 
						id={"formulaPhMax"} name={"formulaPhMax"} 
						value={this.state.formData.formulaPhMax} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaPhMax}>
					</InputText>
					<InputText label={<Translate value="components.labels.minViscosity" />} 
						required={validationFields.formulaViscosityMin == 'required,numeric'} 
						id={"formulaViscosityMin"} name={"formulaViscosityMin"} 
						value={this.state.formData.formulaViscosityMin} action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaViscosityMin} >
					</InputText>
					<InputText 
						label={<Translate value="components.labels.maxViscosity" />} 
						required={validationFields.formulaViscosityMax == 'required,numeric'} 
						id={"formulaViscosityMax"} name={"formulaViscosityMax"} 
						value={this.state.formData.formulaViscosityMax} action={this.handleInputChanged} 
						errMsg={this.state.errors.formulaViscosityMax}>
					</InputText>
					<DataDropdown 
						label={<Translate value="components.labels.viscosityCup" />} 
						required={validationFields.viscosityCupLookupId == 'required'} 
						id={"viscosityCupLookupId"} name={"viscosityCupLookupId"} 
						data={this.state.viscosityCups} value={this.state.formData.viscosityCupLookupId} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.viscosityCupLookupId} >
					</DataDropdown>
					<InputText type={"TextArea"} 
						label={<Translate value="components.labels.notes" />} 
						id={"formulaNotes"} name={"formulaNotes"} 
						value={this.state.formData.formulaNotes} 
						action={this.handleInputChanged} >
					</InputText>
					{this.state.editFormulaId && 
					<span>
					<InputText type={"TextArea"} 
						required={validationFields.newFormulaChangeReason == "required"}
						label={<Translate value="components.labels.changeReason" />} 
						id={"newFormulaChangeReason"} name={"newFormulaChangeReason"} 
						value={this.state.newFormulaChangeReason} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.newFormulaChangeReason} >
					</InputText>
					<Button className="button-right" onClick={() => {alert(this.state.formData.formulaChangeReason);}}>Change Log</Button>
					</span>
					}
					
				</Col>
				<Col md={4}>
					<div className='form-group'>
						<h5>Hazards</h5>
						<InputText label={<Translate value="components.labels.health" />} 
							required={validationFields.formulaHazardHealth == 'required,numeric'} 
							id={"formulaHazardHealth"} name={"formulaHazardHealth"} 
							value={this.state.formData.formulaHazardHealth} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.formulaHazardHealth} >
						</InputText>
						<InputText 
							label={<Translate value="components.labels.flammability" />} 
							required={validationFields.formulaHazardFlammability == 'required,numeric'} 
							id={"formulaHazardFlammability"} name={"formulaHazardFlammability"} 
							value={this.state.formData.formulaHazardFlammability} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.formulaHazardFlammability} >
						</InputText>
						<InputText label={<Translate value="components.labels.reactivity" />} 
							required={validationFields.formulaHazardReactivity == 'required,numeric'} 
							id={"formulaHazardReactivity"} name={"formulaHazardReactivity"} 
							value={this.state.formData.formulaHazardReactivity} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.formulaHazardReactivity} >
						</InputText>
						<InputText 
							label={<Translate value="components.labels.personalProtection" />} 
							required={validationFields.formulaHazardPersonalProtection == 'required,numeric'}  
							id={"formulaHazardPersonalProtection"} 
							name={"formulaHazardPersonalProtection"} 
							value={this.state.formData.formulaHazardPersonalProtection} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.formulaHazardPersonalProtection} >
						</InputText>
					</div>
					<InputText type={'MultiSelect'} label={<Translate 
						value="components.labels.hazardPictograms" />} 
						id={"hazardPictograms"} name={"hazardPictograms"} 
						data={hazardPictogramsList} 
						value={this.state.hazardPictograms} 
						action={this.handleInputChanged}>
					</InputText>
					{bShowCustomerTab &&
					<InputText
						label={<Translate value="components.labels.customers" />} 
						type={"MultiSelect"}
						required={validationFields.customers == 'notEmpty'}
						id={"customers"}
						name={"customers"} 
						value={this.state.customers}
						action={this.handleInputChanged} 
						data={customersList}
						errMsg={this.state.errors.customers}
						maxHeight='90px'
					/>
					}
					<InputText type='CheckBox' label={<Translate value="components.labels.internal" />} id={'bInternalFormula'} name={'bInternalFormula'} action={this.handleInputChanged} value={bInternalFormula} />
					{bInternalFormula &&
						<InputText label={<Translate value="components.labels.components" />} 
							required={validationFields.internalFormulaComponents == 'required'}  
							id={"internalFormulaComponents"} name={"internalFormulaComponents"} type={"MultiSelect"} 
							data={internalFormulasComponentsList} value={this.state.internalFormulaComponents} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.internalFormulaComponents}>
						</InputText>
					}
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h4>Orders</h4>
					<DataGrid 
						columns={ordersColumns} 
						fields={ordersFields} 
						data={this.state.formulaOrders} 
						formatColumns={ordersFormatColumns} 
						onDoubleClick={this.showEditOrderForm}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h4>Components</h4>
					<span style={{color:'red'}}>{this.state.errors.formulaComponents}</span>
					<DataGrid columns={componentsColumns} fields={componentsFields} 
						formatColumns={formatColumns}
						data={this.state.formulaComponents} 
						onDoubleClick={!bCustomer ? this.editFormulaComponent : () => {return false;}} 
						onSelect={this.componentRowSelected}
						selectedRowIndex={this.state.selectedComponentRowIndex}
						moveRowUp={() => {
							var selectedComponentRowIndex = this.state.selectedComponentRowIndex;
							// console.log("moveRowUp() called - selectedComponentRowIndex: ", selectedComponentRowIndex);
							// swap 
							if(selectedComponentRowIndex > 0) {
								var formulaComponents = JSON.parse(JSON.stringify(this.state.formulaComponents));
								
								var tmp = formulaComponents[selectedComponentRowIndex - 1];
								formulaComponents[selectedComponentRowIndex - 1] = formulaComponents[selectedComponentRowIndex];
								formulaComponents[selectedComponentRowIndex] = tmp;
								formulaComponents[selectedComponentRowIndex]['sequenceNumber'] = selectedComponentRowIndex + 1;
								formulaComponents[selectedComponentRowIndex]['bHasChanged'] = true;
								formulaComponents[selectedComponentRowIndex - 1]['sequenceNumber'] = selectedComponentRowIndex;
								formulaComponents[selectedComponentRowIndex - 1]['bHasChanged'] = true;
								selectedComponentRowIndex--;
								this.setState({
									formulaComponents: formulaComponents,
									selectedComponentRowIndex: selectedComponentRowIndex
								});
							}
						}}
						moveRowDown={() => {
							var selectedComponentRowIndex = this.state.selectedComponentRowIndex;
							// console.log("moveRowDown() called - selectedComponentRowIndex: ", selectedComponentRowIndex);
							// swap 
							var formulaComponents = JSON.parse(JSON.stringify(this.state.formulaComponents));
							var numComponents = formulaComponents.length;
							if(selectedComponentRowIndex < numComponents - 1) {
								
								var tmp = formulaComponents[selectedComponentRowIndex + 1];
								formulaComponents[selectedComponentRowIndex + 1] = formulaComponents[selectedComponentRowIndex];
								formulaComponents[selectedComponentRowIndex] = tmp;
								formulaComponents[selectedComponentRowIndex]['sequenceNumber'] = selectedComponentRowIndex + 1;
								formulaComponents[selectedComponentRowIndex]['bHasChanged'] = true;
								formulaComponents[selectedComponentRowIndex + 1]['sequenceNumber'] = selectedComponentRowIndex + 2;
								formulaComponents[selectedComponentRowIndex + 1]['bHasChanged'] = true;
								selectedComponentRowIndex++;
								this.setState({
									formulaComponents: formulaComponents,
									selectedComponentRowIndex: selectedComponentRowIndex
								});
							}
						}}
					/>
					<Button onClick={this.removeFormulaComponent} disabled={loggedInUser.accessFormulasCode == 'V'}><Translate value='components.buttons.delete' /></Button>&nbsp;
					<Button onClick={this.addFormulaComponent} disabled={loggedInUser.accessFormulasCode == 'V'}>Add</Button>
					{orderFormContent}
					{formulaComponentForm}
				</Col>
			</Row>
			<br />
			<Row>
				<Col md={12}>
					<h4>Default Weights</h4>
					<DataGrid 
						columns={defaultWeightsColumns} 
						fields={defaultWeightsFields} 
						formatColumns={{}}
						data={this.state.formulaDefaultWeights} 
						onDoubleClick={!bCustomer ? this.editDefaultWeight : () => {return false;}} 
						onSelect={this.defaultWeightRowSelected}
						selectedRowIndex={this.state.selectedComponentRowIndex}
					/>
					<Button onClick={this.removeDefaultWeight}><Translate value='components.buttons.delete' /></Button>&nbsp;
					<Button onClick={this.addDefaultWeight}>Add</Button>
					{formulaDefaultWeightForm}
				</Col>
			</Row>
		</Form>

	const editFormulaCustomerPricingRow = this.state.editFormulaCustomerPricingRow;
	const formulaCustomerPricingForm = this.state.bShowFormulaCustomerPricingForm ? (<NewFormulaCustomerPricingForm
			visible={this.state.bShowFormulaCustomerPricingForm}
			onClose={() => this.setState({bShowFormulaCustomerPricingForm: false})}
			onSave={this.onSaveFormulaCustomerPricing}
			editFormulaCustomerPricingRow={editFormulaCustomerPricingRow} 
			containers={this.state.containers}
			 />)
		: '';

	const customersTab = <Form className='form-page' horizontal>
		<Row>
			<Col md={4}>
				<h4>Customers</h4>
				<div class='full-height'>
					<DataGrid
						columns={
							[<Translate value="components.labels.customer" />, 
							<Translate value="components.labels.lastOrdered" />]
						}
						fields={['name', 'lastOrderedDate']}
						formatColumns={{1: {date: true}}}
						data={this.state.formulaCustomers}
						onSelect={this.formulaCustomerSelected}
					/>
				</div>
			</Col>
			<Col md={8}>
				<h4>All Formulas</h4>
				{!bSalesPerson &&
				<DataGrid
				columns={[<Translate value="components.labels.formulaNumber" />, 
				<Translate value="components.labels.name" />, 
				<Translate value="components.labels.price" />, 
				<Translate value="components.labels.rawMaterialCost" />]}
				fields={['formula', 'name', 'price', 'rawMaterialCost']}
				formatColumns={{2: {currency: true}, 3: {currency: true}}}
				data={this.state.customerFormulas} />
				}
				<InputText label={<Translate value="components.labels.customerColorName" />} name={'colorName'} value={this.state.customerFormData.colorName != null ? this.state.customerFormData.colorName : ''} action={this.handleCustomerInputChanged} />
				<InputText label={<Translate value="components.labels.customerFormulaNotes" />} name={'notes'} value={this.state.customerFormData.notes != null ? this.state.customerFormData.notes : ''} action={this.handleCustomerInputChanged}  />
				<InputText type='CheckBox' label={<Translate value="components.labels.visibleToCustomer" />} id={'visibleToCustomerFlag'} name={'visibleToCustomerFlag'} action={this.handleCustomerInputChanged} value={this.state.customerFormData.visibleToCustomerFlag} 
				disabled={loggedInUser.formulaVisibleToCustomerFlag == null || !loggedInUser.formulaVisibleToCustomerFlag}
				/>
				{(loggedInUser.formulaPricingBreakdownFlag) && 
				<span>
				<h4><Translate value="components.labels.priceBreakdown" /></h4>
				<Row>
					<Col md={4}>
						<Translate value="components.labels.rawMaterialCost" />: {toCurrency(this.state.rawMaterialCost)}
						<br />	
						{this.state.shrinkageMarkupPercent.toFixed(2)}% <Translate value="components.labels.shrinkageMarkup" />: {toCurrency(this.state.shrinkageMarkup)}
						<br />
						<Translate value="components.labels.basePrice" />: {toCurrency(this.state.basePrice)}
						<br />
					</Col>
					<Col md={8}>
						<Translate value="components.labels.basePrice" /> + <Translate value="components.labels.container" />: {toCurrency(this.state.basePricePlusContainer)}
						<br />
						Base Price + Container + {this.state.overheadMarkupPercent.toFixed(2)}% <Translate value="components.labels.markup" />: {toCurrency(this.state.basePricePlusContainerPlusMarkup)}
						<br />
						<Translate value="components.labels.basePrice" /> + <Translate value="components.labels.container" /> + <Translate value="components.labels.allMarkups" />: {toCurrency(this.state.basePricePlusContainerPlusAllMarkups)}
						<br />
						<Translate value="components.labels.basePrice" /> + <Translate value="components.labels.container" /> + <Translate value="components.labels.markups" /> + <Translate value="components.labels.shipping" />: {toCurrency(this.state.basePricePlusContainerPlusMarkupPlusShipping)} ({Config.weightSymbol})
						{this.state.priceAfterConversion != null &&
						<span>
							&nbsp;&nbsp;<b>{toCurrency(this.state.priceAfterConversion)} ({this.state.unitWt})</b>
						</span>
						}
						<br />
						<InputText type='CheckBox' label={<Translate value="components.labels.approved" />} id={'isApproved'} name={'isApproved'} action={this.handleCustomerInputChanged} value={this.state.customerFormData.isApproved} 
						disabled={!loggedInUser.formulaPricingApprovalFlag}
						/>
					</Col>
				</Row>
				</span>
				}
				{(loggedInUser.accessFormulaPricingCode != 'N') &&
				<span>
					<h4><Translate value="components.labels.priceChart" /></h4>
					<DataGrid
						columns={[<Translate value="components.labels.container" />, 
						<Translate value="components.labels.min" />, 
						<Translate value="components.labels.max" />, 
						<Translate value="components.labels.price" />, 
						<Translate value="components.labels.effectiveDate" />, 
						<Translate value="components.labels.expirationDate" />]}
						fields={['containerName', 'minAmount', 'maxAmount', 'price', 'effectiveDate', 'expirationDate']}
						data={this.state.formulaCustomerPricing} 
						formatColumns={{3:{currency: true}, 4: {'date': true}, 5: {'date': true}}} 
						onSelect={this.formulaCustomerPricingRowSelected}
						onDoubleClick={this.onEditFormulaCustomerPricing} 
					/>
					{(loggedInUser.accessFormulaPricingCode == 'E') &&
					<ButtonGroup>
						<Button onClick={this.onRemoveFormulaCustomerPricing} disabled={loggedInUser.accessFormulaPricingCode == 'V' || this.state.formulaCustomerId == null || this.state.selectedFormulaCustomerPricingRowIndex == null}
							><Translate value='components.buttons.delete' />
						</Button>
						<Button onClick={this.onAddFormulaCustomerPricing} disabled={loggedInUser.accessFormulaPricingCode == 'V' || this.state.formulaCustomerId == null}>
							Add
						</Button>
					</ButtonGroup>
					}
				</span>
				}
				{formulaCustomerPricingForm}
			</Col>
		</Row>
	</Form>

		return(<Modal show={this.props.visible} bsSize="large">
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			{this.state.isLoading ? (
				<Spinner /> 
				):(	
			<React.Fragment>
				<Modal.Body>
					<Tabs defaultActiveKey={this.state.activeTabKey} onSelect={this.handleTabSelect}>
						<Tab eventKey={1} title={<Translate value="components.tabs.formulas" />}>
							{formulasTab}
						</Tab>
						{!bCustomer &&
						<Tab eventKey={2} title={<Translate value="components.tabs.customers" />}>
							{customersTab}
						</Tab>
						}
					</Tabs>
					<FormulaFormDeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
					/>
					<FormulaFormCloseConfirmation
					visible={this.state.showCloseConfirmation}
					onConfirm={this.props.onClose}
					onClose={() => this.setState({showCloseConfirmation: false})}
					/>
				</Modal.Body>
				<Modal.Footer>
					{this.state.validationErrors && 
						<p style={{color:'red'}}><Translate value="components.labels.validationError" /></p>
					}
					{this.state.editFormulaId ? 
					<span>
						<Button
							bsStyle='danger'
							onClick={() => this.setState({showDeleteConfirmation: true})}
							disabled={loggedInUser.accessFormulasCode == 'V'}
						>
							<Translate value="components.buttons.deleteFormula" />
						</Button>
						&nbsp;
						{
							// Spec Output
							this.state.formData.formulaTypeLookupId == constants.formula_type_blending_ink && 
							<Button 
								onClick={() => {
									/*const endpoint = "formulas/export/spec?fid=" 
									+ this.state.formData.id + "&cid=0&d=1930-01-01";
									let url = Config.baseURL +  endpoint;
									*/
									getAPI("formulas/export/specjson?fid="
									+ this.state.formData.id + "&cid=0&d=1930-01-01").then(resSpecOutput => {
										// console.log("resSpecOutput: ", resSpecOutput);
										/*
										let specContent = "Formula_Number,Formula_Name,Pct_Component,Component_Name,ReferenceFormula_Number,Component_Number\n";
										resSpecOutput['data'].forEach(row => {
											specContent += row.formulaNumber + "," 
											+ row.formulaName + "," + row.percentUsed + "," 
											+ row.componentName + "," + row.formulaId + "," 
											+ row.componentNumber + "\n";
										});
										let FileSaver = require('file-saver');
										var blob = new Blob([specContent], 
											{type: "text/csv;charset=utf-8"});
										FileSaver.saveAs(blob, "specOutput" + (new Date()).getTime() + ".csv");
										*/
										saveSpecOutput(resSpecOutput['data']);
									});
								}}
								bsStyle={Config.reportBtnStyle}
							>
								<Translate value="components.reports.specOutput" />
							</Button>
						}
						&nbsp;
						{
							// Blending Report
							this.state.formData.formulaTypeLookupId == constants.formula_type_blending_ink && 
							<Button 
								onClick={() => openBirtReport('BCM_Blending_Ticket', {
										formula: this.state.formData.id,
										customer: bCustomer ? loggedInUser.customerId : 0,
										__format: 'pdf',
									}
								)}
								bsStyle={Config.reportBtnStyle}
							>
								<Translate value="components.reports.blendingTicket" />
							</Button>
						}
						&nbsp;
						{
							// Formula Cost Report
							bShowFormulaCost && 
							<Button 
								onClick={() => openBirtReport('BCM_Formula_Cost_Sheet', {
										formula: this.state.formData.id,
										customer: 0,
									}
								)}
								bsStyle={Config.reportBtnStyle}
							>
								<Translate value="components.reports.formulaCostReport" />
							</Button>
						}
						&nbsp;
					</span>
					: ''}				
					<span style={{color:'red'}}>{this.state.errors.formulaComponents}</span>
					{this.state.unsavedChanges ?//if there are unsaved changes the button has different behavior.
						<Button onClick={()=> {
							this.setState({showCloseConfirmation: true,})
						}}><Translate value="components.buttons.close" /></Button> :
						<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					}
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessFormulasCode == 'V' || this.state.saveDisabled}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</React.Fragment>
				)}
		</Modal>);
	}
}

export default NewFormulaForm;

const validationFieldsComponent = {
	percentUsed: "required,numeric",
	componentId: "required",
};

class NewFormulaComponentForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Add Ink Component",
			errors: resetObject(validationFieldsComponent),
			formData: {
				componentId:null,
				componentNumber:"",
				cost:0.0,
				costPerUm:0.0,
				displayName:"",
				formulaId:0,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::FormulaComponentsListDto",
				name:"",
				percentUsed:null,
				pigment:0,
				resin:0,
				sequenceNumber:0,
			},
			// componentsList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editFormulaComponentRow) { // If this is not null
			const editFormulaComponentRow = this.props.editFormulaComponentRow;

			this.setState({
				formData: editFormulaComponentRow,
				title: editFormulaComponentRow['name'],
			});
		}
	}

	handleInputChanged(event, name) {
		var formData = this.state.formData;
		var value = '';
		
		switch(name) {
			case 'componentId':
				value = event;
				const componentId = value.value;
				const displayName = value.label;
				// console.log("displayName: ", displayName);

				formData['componentId'] = componentId;
				formData['name'] = displayName;
				formData['displayName'] = displayName;
				
				var componentRow = this.props.componentsList.filter(obj => (obj.id == componentId))[0];
				formData['costPerUm'] = componentRow['costPerUm'];
				formData['cost'] 	= roundOff(componentRow['costPerUm'] * (formData['percentUsed'] / 100), 2);
				// console.log("formData['cost']: ", formData['cost']);
				formData['pigment'] = componentRow['pigment'];
				formData['resin'] = componentRow['resin'];
				
				this.setState({
					formData: formData,
				});
			
				break;
				
			
			default:
				const target = event.target;
				value = target.type === 'checkbox' ? target.checked : target.value;
				formData[name] = value;
				switch(name) {
					case 'percentUsed':
						formData['cost'] 	= roundOff(formData['costPerUm'] * (value / 100), 2);
						// console.log("formData['cost']: ", formData['cost']);
						break;
				}		
				this.setState({
				  formData: formData,
				});
				break;
		
		}
	
		
	}
	
	onSave(event) {
		event.preventDefault();
		
		const validation = validateForm(this.state.formData, validationFieldsComponent);
		
		
			
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			var formulaComponent = this.state.formData;
			this.props.onSave(formulaComponent);
		}
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const componentsList = this.props.componentsList.map(obj => ({
			value: obj.id, 
			// label: obj.name + " (" + obj.component + ")"
			label: obj.component + " (" + obj.name + ")"
		}));
		const componentName = this.state.formData.componentId ? 
			componentsList.filter(obj => obj.value == this.state.formData.componentId)[0] : null;
		const activeComponentsList = this.props.activeComponentsList.map(obj => ({
			value: obj.id, 
			// label: obj.name + " (" + obj.component + ")"
			label: obj.component + " (" + obj.name + ")"
		}));
		
		return(
			<Modal show={this.props.visible}>
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<h4><Translate value="components.labels.sequence" /></h4>
						<InputText 
							label={<Translate value="components.labels.percentUsed" />} required 
							id={"percentUsed"} name={"percentUsed"} 
							value={this.state.formData.percentUsed} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.percentUsed} >
						</InputText>
						<InputText 
							label={<Translate value="components.labels.component" />} 
							type={"SingleSelect"} data={activeComponentsList} required 
							id={"componentId"} name={"componentId"} value={componentName} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.componentId} 
							customFilterSort={true}
						>
						</InputText>
						<InputText type='Label' 
							label={<Translate value={"components.labels.costPer" + Config.weightSymbol} />} 
							value={toCurrency(this.state.formData.costPerUm)} />
						<InputText type='Label' 
							label={<Translate value="components.labels.cost" />} 
							value={toCurrency(this.state.formData.cost)} />
						<InputText type='Label' label={<Translate value="components.labels.pigment" />} value={this.state.formData.pigment} />
						<InputText type='Label' label={<Translate value="components.labels.resin" />} value={this.state.formData.resin} />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessFormulasCode == 'V'}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class FormulaDefaultWeightForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "Weight",
			formData: {
				containerId:0,
				containerName: '',
				formulaId:0,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::FormulaWeightExceptionsDto",
				name:null,
				weight:0,
				retired:false,
			},
			component: null, // component selected {value: 1, label: 'abc'}
			// componentsList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editDefaultWeightRow) { // If this is not null
			const editDefaultWeightRow = this.props.editDefaultWeightRow;
			this.setState({
				formData: editDefaultWeightRow,
				title: "Weight: " + editDefaultWeightRow['weight'],
			});
		}
	}

	handleInputChanged(event, name) {
		var formData = this.state.formData;
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;		
		
		switch(name) {
			
			case 'containerId':
				let containerName = value != "" ? this.props.containers.filter(row => row.id == value)[0]['name'] : "";
				formData['containerName'] = containerName;
				break;
		}
		
		formData[name] = value;

		this.setState({
		  formData: formData,
		});
	
		
	}
	
	onSave(event) {
		event.preventDefault();
		var formulaCustomerPricing = this.state.formData;
		this.props.onSave(formulaCustomerPricing);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const containers = mapArrayToObject(this.props.containers, 'id', 'name');
		
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id 
		|| loggedInUser.userTypeId == constants.customer_type_id;
		
		return(
			<Modal show={this.props.visible} bsSize="medium">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={12}>
								<DataDropdown label={<Translate value="components.labels.container" />} required id={"containerId"} name={"containerId"} data={containers} value={this.state.formData.containerId} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.weight" />} id={"weight"} name={"weight"} value={this.state.formData.weight} action={this.handleInputChanged} />
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<br />
						<br />
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessFormulasCode == 'V' || bSalesPerson}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class NewFormulaCustomerPricingForm extends Component {
	constructor(props) {
		super(props);
		var today = new Date;
		today.setHours(0, 0, 0, 0)
		this.state = {
			title: "Pricing",
			formData: {
				containerId:0,
				containerName: '',
				customerId:0,
				effectiveDate:today.getTime(),
				emailSentDate:null,
				expirationDate:null,
				formulaId:0,
				id:0,
				maxAmount:null,
				minAmount:1,
				model:"com.phototype.myinkiq.model.vos.dtos::FormulaCustomerContainerPricingDto",
				name:null,
				price:0.0,
				retired:false,
			},
			component: null, // component selected {value: 1, label: 'abc'}
			// componentsList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editFormulaCustomerPricingRow) { // If this is not null
			const editFormulaCustomerPricingRow = this.props.editFormulaCustomerPricingRow;
			// const containerId = editFormulaCustomerPricingRow['containerId'];
			// editFormulaCustomerPricingRow['containerName'] = this.props.containers.filter(obj => obj.id == containerId)[0]['name'];
			this.setState({
				formData: editFormulaCustomerPricingRow,
				title: "Price: " + editFormulaCustomerPricingRow['price'],
			});
		}
	}

	handleInputChanged(event, name) {
		var formData = this.state.formData;
		var value = '';

		const target = event.target;
		
		switch(name) {
				
			case 'expirationDate':
			case 'effectiveDate':
				value = event._d ? event._d.getTime() : event;
				break;
			
			default:
				value = target.type === 'checkbox' ? target.checked : target.value;
				break;
		}
		
		formData[name] = value;

		this.setState({
		  formData: formData,
		});
	
		
	}
	
	onSave(event) {
		event.preventDefault();
		var formulaCustomerPricing = this.state.formData;
		this.props.onSave(formulaCustomerPricing);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const containers = mapArrayToObject(this.props.containers, 'id', 'name');
		
		const effectiveDate = isNaN(this.state.formData.effectiveDate) || this.state.formData.effectiveDate == 0 ? this.state.formData.effectiveDate : 
		convertTimestampToDate(this.state.formData.effectiveDate);
		
		const expirationDate = (this.state.formData.expirationDate != null) ? isNaN(this.state.formData.expirationDate) || this.state.formData.expirationDate == 0 ? this.state.formData.expirationDate : 
		convertTimestampToDate(this.state.formData.expirationDate) : "";
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id 
		|| loggedInUser.userTypeId == constants.customer_type_id;
		
		return(
			<Modal show={this.props.visible} bsSize="medium">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={12}>
								<DataDropdown label={<Translate value="components.labels.container" />} required id={"containerId"} name={"containerId"} data={containers} value={this.state.formData.containerId} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.min" />} id={"minAmount"} name={"minAmount"} value={this.state.formData.minAmount} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.max" />} id={"maxAmount"} name={"maxAmount"} value={this.state.formData.maxAmount} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.price" />} id={"price"} name={"price"} value={this.state.formData.price} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.effectiveDate" />} id={"effectiveDate"} name={"effectiveDate"} value={effectiveDate} action={this.handleInputChanged} type={'DateTime'} />
								<InputText label={<Translate value="components.labels.expirationDate" />} id={"expirationDate"} name={"expirationDate"} value={expirationDate} action={this.handleInputChanged} type={'DateTime'} />
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessFormulasCode == 'V' || bSalesPerson}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
